<template>
  <div class="sorting-algorithms-table">
    <table>
      <thead>
        <tr>
          <th rowspan="2">Algorithm</th>
          <th colspan="3">Time Complexity</th>
          <th rowspan="2">Space Complexity (Worst)</th>
        </tr>
        <tr>
          <th>Best</th>
          <th>Average</th>
          <th>Worst</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(algorithm, index) in algorithms" :key="index">
          <td>
            {{ algorithm.name }}
          </td>
          <td :class="getComplexityClass(algorithm.best)">
            {{ algorithm.best.value }}
          </td>
          <td :class="getComplexityClass(algorithm.average)">
            {{ algorithm.average.value }}
          </td>
          <td :class="getComplexityClass(algorithm.worst)">
            {{ algorithm.worst.value }}
          </td>
          <td :class="getComplexityClass(algorithm.space)">
            {{ algorithm.space.value }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'SortingAlgorithmsTable',
  data() {
    return {
      algorithms: [
        {
          name: 'Quicksort',
          best: { value: 'Ω(n log(n))', color: 'orange' },
          average: { value: 'Θ(n log(n))', color: 'orange' },
          worst: { value: 'O(n^2)', color: 'red' },
          space: { value: 'O(log(n))', color: 'light-green' },
        },
        {
          name: 'Mergesort',
          best: { value: 'Ω(n log(n))', color: 'orange' },
          average: { value: 'Θ(n log(n))', color: 'orange' },
          worst: { value: 'O(n log(n))', color: 'orange' },
          space: { value: 'O(n)', color: 'yellow' },
        },
        {
          name: 'Timsort',
          best: { value: 'Ω(n)', color: 'yellow' },
          average: { value: 'Θ(n log(n))', color: 'orange' },
          worst: { value: 'O(n log(n))', color: 'orange' },
          space: { value: 'O(n)', color: 'yellow' },
        },
        {
          name: 'Heapsort',
          best: { value: 'Ω(n log(n))', color: 'orange' },
          average: { value: 'Θ(n log(n))', color: 'orange' },
          worst: { value: 'O(n log(n))', color: 'orange' },
          space: { value: 'O(1)', color: 'green' },
        },
        {
          name: 'Bubble Sort',
          best: { value: 'Ω(n)', color: 'yellow' },
          average: { value: 'Θ(n^2)', color: 'red' },
          worst: { value: 'O(n^2)', color: 'red' },
          space: { value: 'O(1)', color: 'green' },
        },
        {
          name: 'Insertion Sort',
          best: { value: 'Ω(n)', color: 'yellow' },
          average: { value: 'Θ(n^2)', color: 'red' },
          worst: { value: 'O(n^2)', color: 'red' },
          space: { value: 'O(1)', color: 'green' },
        },
        {
          name: 'Selection Sort',
          best: { value: 'Ω(n^2)', color: 'red' },
          average: { value: 'Θ(n^2)', color: 'red' },
          worst: { value: 'O(n^2)', color: 'red' },
          space: { value: 'O(1)', color: 'green' },
        },
        {
          name: 'Tree Sort',
          best: { value: 'Ω(n log(n))', color: 'orange' },
          average: { value: 'Θ(n log(n))', color: 'orange' },
          worst: { value: 'O(n^2)', color: 'red' },
          space: { value: 'O(n)', color: 'yellow' },
        },
        {
          name: 'Shell Sort',
          best: { value: 'Ω(n log(n))', color: 'orange' },
          average: { value: 'Θ(n(log(n))^2)', color: 'red' },
          worst: { value: 'O(n(log(n))^2)', color: 'red' },
          space: { value: 'O(1)', color: 'green' },
        },
        {
          name: 'Bucket Sort',
          best: { value: 'Ω(n+k)', color: 'green' },
          average: { value: 'Θ(n+k)', color: 'green' },
          worst: { value: 'O(n^2)', color: 'red' },
          space: { value: 'O(n)', color: 'yellow' },
        },
        {
          name: 'Radix Sort',
          best: { value: 'Ω(nk)', color: 'green' },
          average: { value: 'Θ(nk)', color: 'green' },
          worst: { value: 'O(nk)', color: 'green' },
          space: { value: 'O(n+k)', color: 'yellow' },
        },
        {
          name: 'Counting Sort',
          best: { value: 'Ω(n+k)', color: 'green' },
          average: { value: 'Θ(n+k)', color: 'green' },
          worst: { value: 'O(n+k)', color: 'green' },
          space: { value: 'O(k)', color: 'yellow' },
        },
        {
          name: 'Cubesort',
          best: { value: 'Ω(n)', color: 'yellow' },
          average: { value: 'Θ(n log(n))', color: 'orange' },
          worst: { value: 'O(n log(n))', color: 'orange' },
          space: { value: 'O(n)', color: 'yellow' },
        },
      ],
    };
  },

  methods: {
    getComplexityClass(complexity) {
      return `complexity-${complexity.color}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.sorting-algorithms-table {
  width: 100%;
  // margin: 20px 0;
  // font-family: Arial, sans-serif;
  word-break: auto-phrase;

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: small;

    th,
    td {
      padding: 10px;
      // text-align: left;
      border: 1px solid var(--text-color);
      background-color: var(--page-color);
      color: var(--text-color);
    }

    th {
      // background-color: #0053ca;
      text-align: center;
    }

    tr:nth-child(even) {
      // background-color: #0053ca;
    }

    .complexity-green {
      background-color: #00a300;
      color: #000;
    }
    .complexity-light-green {
      background-color: #4cf64c;
      color: #000;
    }
    .complexity-yellow {
      background-color: #ffff00;
      color: #000;
    }
    .complexity-orange {
      background-color: #ffb328;
      color: #000;
    }
    .complexity-red {
      background-color: #ff6b6b;
      color: #000;
    }
  }
}

@media (max-width: 480px) {
  .sorting-algorithms-table {
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: x-small;
    }
  }
}
</style>
